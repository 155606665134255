import LazyImage from '@/components/shared/LazyImage';
import { UrlLink } from '@/configs/urlLink';
import Link from 'next/link';
import React from 'react';

interface IKnowledge {
  content?: string;
  description?: string;
  slug?: string;
  thumbnail?: string;
  title?: string;
}

export interface ISeriesCardProps {
  thumbnail?: string | null;
  slug?: string | null;
  title?: string | null;
  knowledges?: IKnowledge[];
}

export const HomeSeriesCard = ({
  thumbnail,
  slug,
  title,
  knowledges,
}: ISeriesCardProps) => {
  return (
    <div className="w-full bg-[#FFFFFF] px-2   duration-150 hover:scale-105">
      <Link href={`${UrlLink.knowledge.series}/${slug}`}>
        <div className="relative h-[172px] w-full md:h-[160px] md:max-w-[364px]">
          <LazyImage
            alt={''}
            src={thumbnail}
            className="object-fit"
            fill
            sizes="20vw"
          />
        </div>
      </Link>

      <div className="bg-white px-2 py-3">
        <div className="h-auto py-3 text-justify">
          <Link href={`${UrlLink.knowledge.series}/${slug}`}>
            <p className="text-h5 md:text-PC-Title-Large">{title}</p>
          </Link>
        </div>
        <div className="px-1 py-2">
          <p className="text-PC-Caption-Medium">
            {knowledges?.length} bài viết
          </p>
        </div>
      </div>
    </div>
  );
};
